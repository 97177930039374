<template>
  <div class="pad-container">
    <!-- 顶部搜索栏 -->
    <el-row>
      <div class="pad-container flx-row ali-c">
        <h3>满减设置</h3>
        <el-switch
          v-model="onOff"
          :active-value="1"
          :inactive-value="0"
          class="ml-10 mr-10"
          @change="toggleStatus"
        ></el-switch>
        <span class="text-decoration">开启立刻生效</span>
        <el-button icon="el-icon-plus" class="ml-15" @click="handleAdd" type="primary">添加优惠规则</el-button>
      </div>
    </el-row>
    <el-form class="mt-24" label-width="120px" :model="act_form" :rules="rules" ref="act_form">
      <div class="pad-container mb-10" v-for="(item,index) in act_form.moneyOffs" :key="index">
        <div class="flx-row js-btw">
          <p>{{index==0?'一':'二'}}级优惠</p>
          <el-button icon="el-icon-delete" plain @click="handleDel(index)" type="primary">删除此规则</el-button>
        </div>

        <div class="flx-row ml-60">
          <div class="mt-10">优惠门槛</div>
          <div>
            <el-form-item
              :prop="'moneyOffs.'+index+'.fullMoney'"
              :rules="{required:true,message:'数值不能为空',trigger: 'blur'}"
            >
              满：
              <el-input
                placeholder="请输入数值"
                class="form-input-300"
                v-model="item.fullMoney"
                @keyup.native="item.fullMoney = onInput(item.fullMoney)"
              >
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
            <el-form-item
              :prop="'moneyOffs.'+index+'.discountsMoney'"
              :rules="{required:true,message:'数值不能为空',trigger: 'blur'}"
            >
              减：
              <el-input
                placeholder="请输入数值"
                class="form-input-300"
                v-model="item.discountsMoney"
                @keyup.native="item.discountsMoney = onInput(item.discountsMoney)"
              >
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>
    <div class="flx-row ali-c js-fe mt-50">
      <el-button class="form-button" type="primary" @click="submit">确定</el-button>
    </div>
  </div>
</template>
<script>
import { saveRules, getRules } from "@/api/market/fullcut";
export default {
  data() {
    return {
      act_form: {
        moneyOffs: [
          {
            fullMoney: null,
            discountsMoney: null
          }
        ]
      },
      rules: {},
      onOff: 0
    };
  },
  mounted() {
    this.getRules();
  },
  methods: {
    getRules() {
      getRules().then(res => {
        let data = [];
        res.data.body.moneyOffs.forEach(item => {
          let obj = {};
          obj.discountsMoney = item.discountsMoney;
          obj.fullMoney = item.fullMoney;
          obj.type = item.type;
          data.push(obj);
        });
        this.act_form.moneyOffs = data;
        this.onOff = res.data.body.onOff;
      });
    },
    // 切换满减状态
    toggleStatus(val) {
      // console.log(val);
    },
    // 添加
    handleAdd() {
      if (this.act_form.moneyOffs.length >= 2) {
        this.$message.warning("最多增加二级优惠");
        return;
      }
      this.act_form.moneyOffs.push({
        fullMoney: null,
        discountsMoney: null
      });
    },
    // 删除
    handleDel(i) {
      this.isConfirm(this, () => {
        this.act_form.moneyOffs.splice(i, 1);
      });
    },
    // 提交表单-验证
    submit() {
      this.$refs["act_form"].validate(valid => {
        if (!valid) {
          this.$message.warning("表单信息不完整（有误）");
          return;
        }
        let length = this.act_form.moneyOffs.length;
        // console.log(this.act_form);
        if (
          length == 2 &&
          Number(this.act_form.moneyOffs[0].fullMoney) >=
            Number(this.act_form.moneyOffs[1].fullMoney)
        ) {
          this.$message.warning("一级满金额需小于二级满金额");
          return;
        }
        if (
          length == 2 &&
          Number(this.act_form.moneyOffs[0].discountsMoney) >=
            Number(this.act_form.moneyOffs[1].discountsMoney)
        ) {
          this.$message.warning("一级减金额需小于二级减金额");
          return;
        }
        this.submitForm();
      });
    },
    // 提交表单-请求
    submitForm() {
      this.act_form.moneyOffs.forEach((item, index) => {
        item.type = index;
      });
      const data = Object.assign({}, { onOff: this.onOff }, this.act_form);
      // console.log(data);
      saveRules(data).then(res => {
        this.$message({
          message: "保存成功",
          type: "success"
        });
      });
    }
  }
};
</script>
<style scoped>
.text-decoration {
  color: #666;
  font-size: 14px;
}
.el-form >>> .el-form-item__content {
  margin-left: 20px !important;
}
</style>